/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front-1.webp";
import bgBack from "assets/images/rotating-card-bg-back-1.webp";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Beginne dein
                    <br />
                    Abenteuer jetzt!
                  </>
                }
                description="Und werde Teil unserer Community!"
              />
              <RotatingCardBack
                image={bgBack}
                title="Worauf wartest du?"
                description="Lade unseren Launcher herunter, logge dich ein und los gehts"
                action={{
                  type: "external",
                  route: "https://gearcraft.org/gearcraft_installer.exe",
                  target: "_blank",
                  label: "Download",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Season System"
                  description="Alle 3-4 Monate ein neues Modpack durch Community Voting. Deine Bauwerke bleiben!"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Eigene Mods"
                  description="Der Server wird stätig durch eigene Inhalte erweitert."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Kein Pay2Win"
                  description="Durch Geld ist und wird es niemals möglich sein sich Vorteile zu kaufen."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="house"
                  title="Deine Bauwerke bleiben!"
                  description="Alle deine Bauwerke werden über die Seasons hinweg bestehen bleiben. Wir löschen nur Maschinen und Inventare."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
