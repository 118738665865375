/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import MKProgress from "../../../components/MKProgress";
import PropTypes from "prop-types";
import Testimonials from "../../LandingPages/Rental/sections/Testimonials";
import { forwardRef } from "react";

const Pricing = forwardRef(({ data }, ref_) => {
  return (
    <>
      <MKBox position="relative" variant="gradient" bgColor="info" mt={{ xs: 0, lg: 15 }} mx={-2}>
        <MKBox
          component="img"
          src={bgPattern}
          alt="background-pattern"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "auto", lg: "100%" }}
          height={{ xs: "100%", lg: "auto" }}
          opacity={0.7}
        />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 18 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
              <div ref={ref_}></div>
              <MKBadge badgeContent="premium" color="dark" size="sm" container sx={{ mb: 1 }} />

              <MKTypography variant="h3" color="white" mb={1}>
                Unterstütze unser Projekt!
              </MKTypography>
              <MKTypography variant="body2" color="white">
                Grundsätzlich kannst du GearCraft <b>komplett kostenlos</b> genießen. Es gibt
                <b> kein Pay2Win</b>. Trotzdem kannst du uns unterstützen, für Zusätzliche Claims,
                KI-Bilder die du im Discord generieren kannst und Skins.*
              </MKTypography>

              <MKBadge
                badgeContent="Einnahmen / Kosten"
                color="success"
                size="sx"
                container
                sx={{ mb: 1, pt: 5 }}
              />
              <MKTypography variant="body2" color="white">
                <MKProgress
                  value={data.paypal_percent}
                  variant="gradient"
                  label
                  pre_label={
                    "Die Community steuert aktuell " +
                    data.paypal_sub +
                    " € von 95 € im Monat bei. ("
                  }
                  post_label=")"
                  color="success"
                  label_color="white"
                  sx={{ mb: 1 }}
                />
              </MKTypography>
              <MKTypography variant="body2" color="white">
                Wir nehmen <b>keine Zahlungen</b> von <b>Schülern</b>, <b>Sozialgeldempfängern </b>
                oder Personen in <b>finanziellen Schwierigkeiten</b> an!
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox mt={-19}>
        <Container>
          <Grid container spacing={3} sx={{ mt: 6 }}>
            <Grid item xs={12} sm={6} lg={3}>
              <SimplePricingCard
                color="dark"
                title="Goldschmied"
                description="Unterstüze uns! Mit deinem Beitrag hilfst du uns dabei das Projekt am laufen
                zu halten."
                price={{ value: "4,99 €/Monat" }}
                action={{
                  type: "external",
                  route:
                    "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-00N3987969701062UMCVESRY",
                  label: "Kaufen",
                }}
                specifications={[
                  "Visueller Rang im Discord & Ingame*",
                  "+70 extra Claimchunks monatlich",
                  "5 KI-Bilder erstellen im Monat*",
                  "500 Premium Coins für Skins*",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <SimplePricingCard
                color="dark"
                title="Bankier"
                description="Unterstüze uns! Mit deinem Beitrag hilfst du uns dabei das Projekt am laufen
                zu halten."
                price={{ value: "9,99 €/Monat" }}
                action={{
                  type: "external",
                  route:
                    "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6WF01998T90279902MCVET3I",
                  label: "kaufen",
                }}
                specifications={[
                  "Visueller Rang im Discord & Ingame*",
                  "+150 extra Claimchunks monatlich",
                  "15 KI-Bilder erstellen im Monat*",
                  "1100 Premium Coins für Skins*",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <SimplePricingCard
                variant="gradient"
                color="dark"
                title="Juwelier"
                description="Unterstüze uns! Mit deinem Beitrag hilfst du uns dabei das Projekt am laufen
                zu halten."
                price={{ value: "14,99 €/Monat" }}
                action={{
                  type: "external",
                  route:
                    "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4NN349882R808702NMCVEXGY",
                  label: "kaufen",
                }}
                specifications={[
                  "Visueller Rang im Discord & Ingame*",
                  "+230 extra Claimchunks monatlich",
                  "30 KI-Bilder erstellen im Monat*",
                  "1700 Premium Coins für Skins*",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <SimplePricingCard
                color="dark"
                title="Aktionär"
                description="Unterstüze uns! Mit deinem Beitrag hilfst du uns dabei das Projekt am laufen
                zu halten."
                price={{ value: "19,99 €/Monat" }}
                action={{
                  type: "external",
                  route:
                    "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4NN349882R808702NMCVEXGY",
                  label: "kaufen",
                }}
                specifications={[
                  "Visueller Rang im Discord & Ingame*",
                  "+320 extra Claimchunks monatlich",
                  "50 KI-Bilder erstellen im Monat*",
                  "2100 Premium Coins für Skins*",
                ]}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} flexDirection="column" sx={{ textAlign: "center" }}>
            <MKTypography variant="body2" color="text" mt={6} mb={1} mx="auto">
              *Diese Permiuminhalte befinden sich noch in der Entwicklung und sind aktuell nicht
              verfügbar. Diese werden rückwirkend verteilt. Bitte beachtet das die Gelder
              hauptsächlich dafür da sind das Projekt am Leben zu erhalten und die Serverkosten zu
              decken.
            </MKTypography>
            <MKTypography variant="body2" color="text" mx="auto" fontWeight="bold">
              Wir nehmen keine Zahlungen von Schülern, Sozialgeldempfängern oder Personen in
              finanziellen Schwierigkeiten an!
            </MKTypography>
            <MKTypography variant="body2" color="text" mt={6} mb={3} mx="auto">
              <Icon>lock</Icon> Secured Payment by:
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2} mx="auto">
              <i className="fa fa-cc-paypal fa-2x" />
            </MKTypography>
            <MKTypography variant="body2" color="text" mx="auto" sx={{ maxWidth: "500px" }}>
              <b>Info:</b> Alle Preise sind inkl. MwSt.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
});

Pricing.propTypes = {
  data: PropTypes.shape({
    paypal_sub: PropTypes.number.isRequired,
    paypal_percent: PropTypes.number.isRequired,
  }).isRequired,
};

export default Pricing;
