/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import { HashLink } from "react-router-hash-link";
// import MKSocialButton from "components/MKSocialButton";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Information from "pages/presentation/sections/Information";
// import DesignBlocks from "pages/presentation/sections/DesignBlocks";
// import AuthPages from "pages/presentation/sections/AuthPages";
// import Pages from "pages/presentation/sections/Pages";
// // import Testimonials from "pages/presentation/sections/Testimonials";
import Pricing from "pages/presentation/sections/Pricing";
import Team from "pages/Company/AboutUs/sections/Team";
import Testimonials from "pages/LandingPages/Rental/sections/Testimonials";
import FeaturesThree from "layouts/sections/page-sections/featuers/components/FeaturesThree";
// Presentation page components
// import BuiltByDevelopers from "pages/presentation/components/BuiltByDevelopers";
import { Route, Switch, useLocation } from "react-router-dom";
// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation-mc-4.webp";

import React, { useState, useEffect, useRef } from "react";

function Presentation() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const recogRef = useRef(null);

  useEffect(() => {
    const checkAndScroll = () => {
      const hash = window.location.hash;
      if (hash === "#pricing" && recogRef.current) {
        recogRef.current.scrollIntoView({ behavior: "instant", alightToTop: true });
      } else {
        // Setzt einen Timeout, wenn das Element noch nicht im DOM verfügbar ist
        setTimeout(checkAndScroll, 100);
      }
    };

    // Initiieren des Check-and-Scroll Prozesses
    checkAndScroll();
  }, []);

  useEffect(() => {
    const fetchData = () => {
      fetch("https://api.gearcraft.org")
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setUserData(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    };
    fetchData();
    const intervalId = setInterval(fetchData, 10000);

    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://gearcraft.org/gearcraft_installer.exe",
          label: "Jetzt Mitspielen",
          color: "success",
        }}
        sticky
      />
      <MKBox
        minHeight="60vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} mt={10} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Gear Craft{" "}
              <MKBadge
                badgeContent="MC Server"
                size="lg"
                variant="contained"
                color="white"
                container
                sx={{ mt: -4 }}
              />
            </MKTypography>
            <MKTypography variant="body1" color="white" textAlign="center" px={10} mt={2}>
              ✨ Langzeit - Modded - Technik ✨
            </MKTypography>
            <Grid container justifyContent="center" mt={3}>
              <Grid item xs={3} textAlign="center">
                <MKButton
                  textAlign="center"
                  variant="gradient"
                  color="success"
                  href="https://gearcraft.org/gearcraft_installer.exe"
                  target="_blank"
                >
                  Jetzt Mitspielen
                </MKButton>
              </Grid>
              <Grid item xs={3} textAlign="center">
                <MKButton
                  textAlign="center"
                  variant="gradient"
                  color="info"
                  href="https://discord.gg/P9DDuF5q8H"
                >
                  Discord
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { background_lp }, functions: { rgba } }) =>
            rgba(background_lp.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters data={userData.data} />
        <Information />
        <Testimonials data={userData.data} />
        <FeaturesThree />
        <Team />
        <div ref={recogRef}></div>
        <Pricing data={userData.data} />
        {/*<MKBox pt={18} pb={6}>*/}
        {/*  <Container>*/}
        {/*    <Grid container spacing={3}>*/}
        {/*      <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>*/}
        {/*        <MKTypography variant="h4" fontWeight="bold" mb={0.5}>*/}
        {/*          Thank you for your support!*/}
        {/*        </MKTypography>*/}
        {/*        <MKTypography variant="body1" color="text">*/}
        {/*          We deliver the best web products*/}
        {/*        </MKTypography>*/}
        {/*      </Grid>*/}
        {/*      <Grid*/}
        {/*        item*/}
        {/*        xs={12}*/}
        {/*        lg={5}*/}
        {/*        my={{ xs: 5, lg: "auto" }}*/}
        {/*        mr={{ xs: 0, lg: "auto" }}*/}
        {/*        sx={{ textAlign: { xs: "center", lg: "right" } }}*/}
        {/*      >*/}
        {/*        <MKSocialButton*/}
        {/*          component="a"*/}
        {/*          href="https://twitter.com/intent/tweet?text=Check%20Material%20Design%20System%20made%20by%20%40CreativeTim%20%23webdesign%20%23designsystem%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-design-system-pro"*/}
        {/*          target="_blank"*/}
        {/*          color="twitter"*/}
        {/*          sx={{ mr: 1 }}*/}
        {/*        >*/}
        {/*          <i className="fab fa-twitter" />*/}
        {/*          &nbsp;Tweet*/}
        {/*        </MKSocialButton>*/}
        {/*        <MKSocialButton*/}
        {/*          component="a"*/}
        {/*          href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-design-system-pro"*/}
        {/*          target="_blank"*/}
        {/*          color="facebook"*/}
        {/*          sx={{ mr: 1 }}*/}
        {/*        >*/}
        {/*          <i className="fab fa-facebook" />*/}
        {/*          &nbsp;Share*/}
        {/*        </MKSocialButton>*/}
        {/*        <MKSocialButton*/}
        {/*          component="a"*/}
        {/*          href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-design-system-pro"*/}
        {/*          target="_blank"*/}
        {/*          color="pinterest"*/}
        {/*        >*/}
        {/*          <i className="fab fa-pinterest" />*/}
        {/*          &nbsp;Pin it*/}
        {/*        </MKSocialButton>*/}
        {/*      </Grid>*/}
        {/*    </Grid>*/}
        {/*  </Container>*/}
        {/*</MKBox>*/}
      </Card>
      {/*<MKBox pt={6} px={1} mt={6}>*/}
      {/*  <DefaultFooter content={footerRoutes} />*/}
      {/*</MKBox>*/}
    </>
  );
}

export default Presentation;
