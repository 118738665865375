/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function FeaturesThree() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={6}
        >
          <MKTypography variant="h2" mb={1}>
            Wieso GearCraft?
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Regelmäßig wechselnde Seasons mit von der Community gewählten Technik-Modpacks bieten
            ein stets frisches Spielerlebnis.{" "}
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="primary"
              icon="person"
              title="Nette und aktive Community"
              description="Unsere Spieler helfen gerne und halten zusammen. Egal ob im Voice oder Chat."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              icon="update"
              title="Wechselne Modpacks"
              description="Alle 3-4 Monate wechselt das Modpack, ihr entscheided welches wir Spielen. Der Clue dabei: Alle eure Gebäude bleiben erhalten!"
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="warning"
              icon="lightbulb"
              title="Eigene Inhalte"
              description="Wir arbeiten stätig daran, auch eigene Inhalte hinzuzufügen und euch ein einzigartiges Spielerlebnis zu liefern."
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FeaturesThree;
