/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

// Material Kit 2 PRO React components
import SimpleReviewCard from "examples/Cards/ReviewCards/SimpleReviewCard";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
// import team2 from "assets/images/team-2.jpg";
import PropTypes from "prop-types"; // Import PropTypes
import React, { useEffect, useState } from "react";

function Testimonials({ data }) {
  const [randomIndices, setRandomIndices] = useState([]);

  useEffect(() => {
    // Function to generate three unique random indices
    const generateRandomIndices = () => {
      const indices = new Set();
      while (indices.size < 3) {
        const randomIndex = Math.floor(Math.random() * data.feedback.length);
        indices.add(randomIndex);
      }
      return Array.from(indices);
    };

    // Set the random indices
    setRandomIndices(generateRandomIndices());
  }, []);
  return (
    <MKBox position="relative" variant="gradient" bgColor="dark" mt={6} py={2} mx={-2}>
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.2}
        display={{ xs: "none", md: "block" }}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{ pt: 8, pb: 5, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <MKBadge
              badgeContent="community"
              variant="contained"
              color="white"
              size="sm"
              container
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" color="white" mb={1}>
              Feedback aus der Community
            </MKTypography>
            <MKTypography variant="body1" color="white" fontWeight="light">
              So denkt die Community über unseren Server
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 10, mb: 6 }}>
          {randomIndices.map((index, idx) => (
            <Grid item xs={12} md={4} key={idx} sx={{ mt: { xs: 12, md: 0 } }}>
              <SimpleReviewCard
                image={data.feedback[index].avatar}
                name={data.feedback[index].author}
                username={data.feedback[index].name}
                review={data.feedback[index].feedback}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

Testimonials.propTypes = {
  data: PropTypes.shape({
    active_players: PropTypes.number.isRequired,
    online_players: PropTypes.number.isRequired,
    season_days_left: PropTypes.number.isRequired,
    feedback: PropTypes.array.isRequired,
  }).isRequired,
};

export default Testimonials;
